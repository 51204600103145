import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

export const config = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY!,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN!,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID!,
};

const app = initializeApp(config);
export const auth = getAuth(app);
export const getAuthToken = async () => {
  try {
    const token = (await auth.currentUser?.getIdToken()) ?? '';
    return `Bearer ${token}`;
  } catch (error) {
    throw new Error();
  }
};
