import type { ComponentMultiStyleConfig } from '@chakra-ui/theme';

export const Input: ComponentMultiStyleConfig = {
  parts: ['field'],
  variants: {
    outline: {
      field: {
        background: 'base.0',
        _disabled: {
          opacity: 0.5,
          background: 'base.1',
          color: 'base.4',
          borderColor: 'inherit',
        },
        _hover: {
          borderColor: 'base.4',
        },
        _invalid: {
          borderColor: 'red.5',
        },
        _focusVisible: {
          borderColor: 'blue.5',
        },
      },
    },
  },
};
