import type { ComponentSingleStyleConfig } from '@chakra-ui/theme';
import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  _hover: {
    background: 'base.3',
  },
  _active: {
    background: 'base.3',
  },
  _focus: {
    outline: 'none',
    boxShadow: 'none',
  },
  _focusVisible: {
    outlineColor: 'blue.3',
    outlineStyle: 'solid',
    outlineWidth: '2px',
  },
};

export const CloseButton: ComponentSingleStyleConfig = {
  baseStyle,
};
