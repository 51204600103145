import type { ComponentSingleStyleConfig } from '@chakra-ui/theme';
import type {
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  _focusVisible: {
    outlineColor: 'blue.3',
    outlineStyle: 'solid',
    outlineWidth: '2px',
    boxShadow: 'none',
  },
};

const variantSolid: SystemStyleFunction = ({ colorScheme }) => ({
  bg: `${colorScheme}.5`,
  _hover: {
    bg: `${colorScheme}.6`,
    color: `${colorScheme}.2`,
  },
  _active: {
    bg: `${colorScheme}.6`,
    color: `${colorScheme}.2`,
  },
  _disabled: {
    bg: `${colorScheme}.3`,
    color: `${colorScheme}.1`,
  },
});

const variantOutline: SystemStyleFunction = ({ colorScheme }) => {
  const color = colorScheme === 'blue' ? 'base' : colorScheme;

  return {
    color: `${color}.5`,
    borderColor: 'base.4',
    _hover: {
      color: `${color}.5`,
      bg: 'base.3',
    },
    _active: {
      color: `${color}.5`,
      bg: 'base.3',
    },
    _disabled: {
      color: `${color}.4`,
    },
  };
};

const variantLink: SystemStyleFunction = ({ colorScheme }) => ({
  color: `${colorScheme}.5`,
  _hover: {
    color: `${colorScheme}.6`,
    textDecoration: 'none',
  },
  _active: {
    color: `${colorScheme}.6`,
  },
  _disabled: {
    color: `${colorScheme}.3`,
  },
});

const variants = {
  solid: variantSolid,
  outline: variantOutline,
  link: variantLink,
};

const sizes: SystemStyleObject = {
  lg: {
    textStyle: 'md.1',
  },
  md: {
    textStyle: 'md.1',
  },
  sm: {
    textStyle: 'xs.1',
  },
  xs: {
    textStyle: 'xs.1',
  },
};

export const Button: ComponentSingleStyleConfig = {
  baseStyle,
  variants,
  sizes,
};
