import { createFileRoute, Outlet } from '@tanstack/react-router';
import { DriverLoggedIn } from '../../providers/Authentication';
import { Loading } from '../../components/loading/Loading';

export const Route = createFileRoute('/app/_driverloggedin')({
  component: AppDriver,
  pendingComponent: Loading,
});

function AppDriver() {
  return (
    <DriverLoggedIn>
      <Outlet />
    </DriverLoggedIn>
  );
}
