import { FetchSpotMetaResponse } from '../../../../api/spot/queries';

export const selector = (
  meta: FetchSpotMetaResponse['meta'],
): Record<string, boolean | string> => {
  const result: { [key: string]: boolean | string } = {};
  for (const key in meta) {
    if (typeof meta[key] === 'boolean' || typeof meta[key] === 'string') {
      result[key] = meta[key];
    } else if (meta[key] != null) {
      result[key] = String(meta[key]);
    }
  }
  return result;
};
