import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import { MapRef } from 'react-map-gl';
import { JSONSchema7 } from 'json-schema';
import { DateTime } from 'luxon';
import { SpotData } from './types/SpotData';
import { type MapPing } from '../../components/map';
import { type User } from '../../recoil/authorizedInfo/authorizedInfoSelector';
import { SchemaDescription } from './components/SchemaDescription/SchemaDescription';
import { RepresentativeLocation } from './components/RepersentitiveLocation';
import { GuidanceLocation } from './components/GuidanceLocation';
import { ParkingLocation } from './components/ParkingLocation';
import { MarkerLayerMap } from '../../components/markerLayerMap';

export interface SpotDetailProps {
  spotData: SpotData;
  metaSchemaObject?: JSONSchema7 | null;
  user?: User;
  mapRef: React.MutableRefObject<MapRef | null>;
  moveToTagetMapPlace: ({
    longitude,
    latitude,
  }: {
    longitude: number;
    latitude: number;
  }) => void;
  markers?: MapPing[];
}

export const SpotDetail: React.FC<SpotDetailProps> = ({
  spotData,
  metaSchemaObject,
  user,
  mapRef,
  moveToTagetMapPlace,
  markers,
}) => {
  const navigate = useNavigate();

  return (
    <Flex flexDirection={'column'} overflowY={'scroll'}>
      <Flex
        backgroundColor="base.2"
        width={'100%'}
        borderBottom={'1px'}
        borderColor={'base.3'}
      >
        <Flex
          width={'100%'}
          marginTop={'24px'}
          marginBottom={'16px'}
          marginLeft={'8px'}
          marginRight={'13px'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Text textStyle={'md.1'}>{spotData?.displayName}</Text>
          <Button
            variant={'solid'}
            height={'32px'}
            width={'72px'}
            size={'sm'}
            onClick={() => {
              navigate({ to: `/app/${user?.role}/spots/${spotData.id}/edit` });
            }}
          >
            編集
          </Button>
        </Flex>
      </Flex>
      <Flex margin={'16px'}>
        <Text textStyle={'xs.2'}>
          作成日{' '}
          {DateTime.fromISO(spotData?.createdAt || '').toFormat(
            'yyyy年MM月dd日',
          )}{' '}
          / 更新日{' '}
          {DateTime.fromISO(spotData?.updatedAt || '').toFormat(
            'yyyy年MM月dd日',
          )}
        </Text>
      </Flex>
      <Flex marginLeft={'16px'} marginRight={'16px'} flexDirection={'column'}>
        <Flex marginBottom={'24px'} alignItems={'center'}>
          <Text marginRight={'8px'} textStyle={'md.1'}>
            ID
          </Text>
          <Text textStyle={'sm.2'}>{spotData?.id}</Text>
        </Flex>
        <VStack marginBottom={'24px'} align="baseline">
          <Text textStyle={'md.1'}>住所</Text>
          <Text textStyle={'sm.2'}>
            {spotData?.prefecture}
            {spotData?.street}
            {spotData?.building}
          </Text>
        </VStack>
      </Flex>
      <Flex flexDirection={'column'}>
        <Box marginLeft={'16px'}>
          <Text textStyle={'md.1'}>位置</Text>
        </Box>

        <Box
          margin={'16px'}
          height={'272px'}
          border={'1px solid'}
          borderColor={'base.3'}
          borderRadius={'4px'}
        >
          <MarkerLayerMap
            defaultCenter={{
              lat: spotData?.representativeLocation.latitude || 0,
              lng: spotData?.representativeLocation.longitude || 0,
            }}
            mapRef={mapRef}
            markers={markers}
            isDriver={user?.role === 'driver'}
          />
        </Box>
      </Flex>
      <Flex>
        <Box height="100%" marginLeft={'16px'}>
          <RepresentativeLocation
            geocode={spotData.representativeLocation}
            panTo={moveToTagetMapPlace}
          />
          {spotData.guidanceLocations.map((guidanceLocation, index) => (
            <GuidanceLocation
              key={index}
              guidanceIndex={index}
              title={
                guidanceLocation?.geocode
                  ? `案内希望位置${index + 1}`
                  : `案内希望位置${index + 1} (未指定)`
              }
              travelDuration={guidanceLocation?.travelDuration}
              geocode={guidanceLocation?.geocode}
              panTo={moveToTagetMapPlace}
            />
          ))}
          <ParkingLocation
            title={
              spotData.parkingLocation?.geocode
                ? '駐車位置'
                : '駐車位置 (未指定)'
            }
            geocode={spotData.parkingLocation?.geocode}
            panTo={moveToTagetMapPlace}
          />
        </Box>
      </Flex>
      <Flex
        flexDirection={'column'}
        marginLeft={'16px'}
        marginBottom={'16px'}
        gap={'8px'}
      >
        <Text textStyle={'md.1'}>指示・特記（選択）</Text>
        {spotData?.meta &&
        Object.keys(spotData.meta).length > 0 &&
        Object.values(spotData.meta).includes(true) ? (
          <Flex height="100%" wrap="wrap" gap="2">
            <SchemaDescription
              meta={spotData?.meta}
              schema={metaSchemaObject}
            />
          </Flex>
        ) : (
          <Text textStyle={'md.2'}>選択されている指示・特記はありません。</Text>
        )}
      </Flex>
    </Flex>
  );
};
