import { Navigate } from '@tanstack/react-router';
import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react';
import logo from '../../assets/masterdata_logo.svg';
import { LoginField } from './components/loginField';
import { Button } from '../../components/button';
import { useLoginForm } from './hooks';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { isLoggedInValue } from '../../recoil/authorizedInfo';
import { userValue } from '../../recoil/authorizedInfo';

export const Login: React.FC = () => {
  const {
    emailErrorMessage,
    passwordErrorMessage,
    buttonErrorMessage,
    login,
    register,
    watch,
    handleSubmit,
  } = useLoginForm();

  const isLoggedIn = useRecoilValue(isLoggedInValue);
  const user = useRecoilValue(userValue);
  const refresh = useRecoilRefresher_UNSTABLE(isLoggedInValue);

  if (isLoggedIn) {
    if (user?.role !== 'manager') {
      throw new Error('unauthorized user');
    }
    return <Navigate to={`/app/manager/spots`} />;
  }

  return (
    <Flex
      height="100vh"
      justify="center"
      align="center"
      backgroundColor="base.0"
    >
      <Box margin="auto">
        <Flex marginBottom={9} justifyContent="center" paddingX="10">
          <Image src={logo} alt="Loogia" display="inline-block" />
          <br />
        </Flex>
        <form onSubmit={handleSubmit(login)}>
          <Stack direction="column" gap="14" display="flex" align="center">
            <Stack
              direction="column"
              gap="4"
              width={{ base: '90%', md: '100%' }}
            >
              <LoginField
                label="メールアドレス"
                type="email"
                id="email"
                placeholder="example@optimind.tech"
                errorMessage={emailErrorMessage}
                register={register}
                watch={watch}
              />
              <LoginField
                label="パスワード"
                type="password"
                id="password"
                placeholder="パスワード"
                errorMessage={passwordErrorMessage}
                register={register}
                watch={watch}
              />
            </Stack>

            <Stack
              direction="column"
              gap="1"
              width={{ base: '90%', md: '100%' }}
            >
              {!!buttonErrorMessage && (
                <Text textStyle="xs.2" color="red.5">
                  {buttonErrorMessage}
                </Text>
              )}
              <Button
                type="submit"
                data-tracking-id="sign-in-button"
                width="100%"
                onClick={() => {
                  refresh();
                }}
              >
                ログイン
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
};
