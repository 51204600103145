import React, { useEffect, useState } from 'react';
import { Marker, MapRef } from 'react-map-gl';
import { Map, MapPing } from '../map';
import { LocationPing, PingLocationType } from '../map/types/MapPingType';
import { ActiveCurrentPositionMarker } from '../map/activeCurrentPositionMarker';
import { isMobile } from '../../utils/mobileDetector';

type MarkerLayerMapProps = {
  defaultCenter: { lat: number; lng: number };
  mapRef: React.MutableRefObject<MapRef | null>;
  defaultZoom?: number;
  markers?: MapPing[];
  children?: React.ReactNode;
  isDriver?: boolean;
};

export const MarkerLayerMap: React.FC<MarkerLayerMapProps> = ({
  defaultCenter,
  defaultZoom = 18,
  mapRef,
  markers,
  children,
  isDriver,
}) => {
  const [currentPosition, setCurrentPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  useEffect(() => {
    if (isDriver && isMobile() && navigator.geolocation) {
      const watchId = navigator.geolocation.watchPosition(
        (position) => {
          setCurrentPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.log(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
        },
      );
      return () => {
        navigator.geolocation.clearWatch(watchId);
      };
    }
  }, [currentPosition?.lat, currentPosition?.lng, isDriver]);

  return (
    <Map
      defaultCenter={{
        lat: defaultCenter.lat,
        lng: defaultCenter.lng,
      }}
      defaultZoom={defaultZoom}
      mapRef={mapRef}
      isDriver={isDriver}
    >
      {currentPosition && (
        <ActiveCurrentPositionMarker
          lat={currentPosition.lat}
          lng={currentPosition.lng}
        />
      )}
      {markers?.map((pin, index) => (
        <Marker
          longitude={pin.geocode?.lng ?? 0}
          latitude={pin.geocode?.lat ?? 0}
          anchor="bottom"
          style={{
            zIndex:
              pin.locationType === PingLocationType.REPRESENTITIVE_POSITION
                ? 1
                : 0,
          }}
          key={index}
        >
          <div>{LocationPing[pin.locationType]?.icon}</div>
        </Marker>
      ))}
      {children}
    </Map>
  );
};
