import React from 'react';
import { Geocode } from '../../types/SpotData';
import { BaseLocation } from '../BaseLocation';
import { ParkingLocationIcon } from '../../../../components/icons/ParkingLocationIcon';

interface ParkingLocationProps {
  geocode?: Geocode;
  title: string;
  panTo: ({ longitude, latitude }: Geocode) => void;
}

export const ParkingLocation: React.FC<ParkingLocationProps> = ({
  geocode,
  title,
  panTo,
}) => {
  return (
    <BaseLocation
      icon={<ParkingLocationIcon />}
      title={title}
      geocode={geocode}
      panTo={panTo}
    />
  );
};
