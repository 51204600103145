import { selector } from 'recoil';
import { authorizedInfoValue } from './authorizedInfoSelector';

export const organizationValue = selector({
  key: 'authorizedOrganization',
  get({ get }) {
    const result = get(authorizedInfoValue);

    return result.isLoggedIn ? result.organization : null;
  },
});
