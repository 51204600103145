/**
 * Components/index.ts
 *
 * コンポーネントのテーマ設定についてオーバーライドします。
 * 各項目について、オーバーライドするオブジェクトを別ファイルにて定義し、
 * このファイルにimportしてください。
 *
 * オーバーライドできるテーマ設定については、以下を参照してください。
 * https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/components
 */

import { Accordion } from './Disclosure/Accordion';
import { Button } from './Forms/Button';
import { Badge } from './DataDisplay/Badge';
import { Editable } from './Forms/Editable';
import { Divider } from './DataDisplay/Divider';
import { Table } from './DataDisplay/Table';
import { Input } from './Forms/Input';
import { Popover } from './Overlay/Popover';
import { NumberInput } from './Forms/NumberInput';
import { Textarea } from './Forms/Textarea';
import { Checkbox } from './Forms/Checkbox';
import { Alert } from './Feedback/Alert';
import { Menu } from './Overlay/Menu';
import { CloseButton } from './Other/CloseButton';
import { Tag } from './DataDisplay/Tag';

export const components = {
  Accordion,
  Button,
  Badge,
  Editable,
  Divider,
  Table,
  Input,
  Popover,
  NumberInput,
  Textarea,
  Checkbox,
  Alert,
  Menu,
  CloseButton,
  Tag,
};
