import { Button, Flex, Text } from '@chakra-ui/react';

export const Error500 = () => (
  <Flex
    flexFlow="column"
    h="100vh"
    justify="center"
    align="center"
    backgroundColor="base.1"
  >
    <Text fontSize="36px" fontWeight="700" marginBottom="24px">
      予期せぬエラーが発生しました。
    </Text>

    <Flex flexFlow="column" marginBottom="48px">
      <Text fontSize="16px" fontWeight="700" color="base.5" marginBottom="8px">
        エラーが発生したため、お手数ですが再度お試しください。
      </Text>
      <Text fontSize="16px" fontWeight="700" color="base.5">
        何度も発生する場合は、お問い合わせください。
      </Text>
    </Flex>

    <Button
      marginBottom="24px"
      onClick={() => {
        history.go(-2);
      }}
      width="320px"
      height="50px"
      color="base.5"
      backgroundColor="base.0"
      borderWidth="1px"
      borderColor="base.4"
      fontWeight="700"
      _hover={{
        backgroundColor: 'base.0',
      }}
    >
      直前のページに戻る
    </Button>

    {/* @fixme: masterdata用の問い合わせフォームを生成してから、以下hrefを置き換えること */}
    {/* <Link
      isExternal
      _hover={{
        textDecoration: 'none',
      }}
      href="https://docs.google.com/forms/d/e/1FAIpQLSeLuICSu2vx8wmMKjwbOLbxG8RzzberwlnTWiYkFtOhnbGtnQ/viewform"
    >
      <Button
        width="320px"
        height="50px"
        color="base.5"
        backgroundColor="base.0"
        borderWidth="1px"
        borderColor="base.4"
        fontWeight="700"
        _hover={{
          backgroundColor: 'base.0',
        }}
      >
        お問い合わせ
        <Launch />
      </Button>
    </Link> */}
  </Flex>
);
