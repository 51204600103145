import React, { useEffect, useState, useCallback, RefObject } from 'react';
import ReactMapGL, {
  NavigationControl,
  ViewStateChangeEvent,
  MapRef,
} from 'react-map-gl';
import { Box, IconButton } from '@chakra-ui/react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { getAuth } from 'firebase/auth';
import { PingLocationType } from './types/MapPingType';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import { ZenrinSource } from './components/ZenrinSource';
import { useRecoilValue } from 'recoil';
import { userValue } from '../../recoil/authorizedInfo';

type Geocode = {
  lat: number;
  lng: number;
} | null;

export type MapPing = {
  locationType: PingLocationType;
  geocode: Geocode;
};

type Center = {
  lat: () => number;
  lng: () => number;
};

type OnMoveEvent = {
  getCenter: () => Center;
};

export type MapProps = {
  defaultCenter?: Geocode;
  defaultZoom?: number;
  children?: React.ReactNode;
  isModeEdit?: boolean;
  onMove?: (event: OnMoveEvent) => void;
  onMoveEnd?: (event: OnMoveEvent) => void;
  mapRef?: RefObject<MapRef>;
  isDriver?: boolean;
};

const MAP_STYLE_LINK = 'mapbox://styles/optimind/clq1ywzoo000601rf5inlhasb';
const MAPBOX_API_BASE_URL = 'https://mapbox-apigw-test.loogia.tech';

const getToken = async (): Promise<string> => {
  const requestURL = `${import.meta.env.VITE_MAPBOX_BASE_API_URL ?? MAPBOX_API_BASE_URL}/request_token`;
  const options = {
    method: 'POST',
    headers: {
      authorization: `Bearer ${await getAuth().currentUser?.getIdToken()}`,
    },
  };

  const response = await fetch(requestURL, options);
  const data = await response.json();
  return data.token;
};

export const Map: React.FC<MapProps> = ({
  defaultCenter,
  defaultZoom = 12,
  onMove,
  onMoveEnd,
  children,
  mapRef,
  isDriver = false,
}) => {
  const user = useRecoilValue(userValue);
  const [token, setToken] = useState<string>('');
  const [isZenrinMode, setIsZenrinMode] = useState<boolean>(false);

  useEffect(() => {
    getToken().then(setToken);
  }, []);

  const handleMoveEvent = useCallback(
    (event: ViewStateChangeEvent) => {
      if (onMove) {
        onMove({
          getCenter: () => ({
            lat: () => event.viewState.latitude,
            lng: () => event.viewState.longitude,
          }),
        });
      }
    },
    [onMove],
  );

  const handleMoveEndEvent = useCallback(
    (event: ViewStateChangeEvent) => {
      if (onMoveEnd) {
        onMoveEnd({
          getCenter: () => ({
            lat: () => event.viewState.latitude,
            lng: () => event.viewState.longitude,
          }),
        });
      }
    },
    [onMoveEnd],
  );

  if (!token) {
    return null;
  }

  return (
    <Box position="relative" width="100%" height="100%">
      <ReactMapGL
        ref={mapRef}
        baseApiUrl={`${import.meta.env.VITE_MAPBOX_BASE_API_URL ?? MAPBOX_API_BASE_URL}`}
        initialViewState={{
          latitude: defaultCenter?.lat || 0,
          longitude: defaultCenter?.lng || 0,
          zoom: defaultZoom,
        }}
        mapStyle={MAP_STYLE_LINK}
        onMove={handleMoveEvent}
        onMoveEnd={handleMoveEndEvent}
        mapboxAccessToken={token}
        minZoom={3}
        maxZoom={21}
      >
        {isZenrinMode && <ZenrinSource />}
        {children}
        <NavigationControl position="bottom-right" />
      </ReactMapGL>
      {isDriver && user?.enableZenrin && (
        <Box position="absolute" top="10px" right="10px">
          <IconButton
            icon={<LayersOutlinedIcon />}
            color={isZenrinMode ? 'blue.5' : 'base.5'}
            variant="outlined"
            size="md"
            aria-label="Toggle Layers"
            backgroundColor="white"
            onClick={() => setIsZenrinMode((prevMode) => !prevMode)}
          />
        </Box>
      )}
    </Box>
  );
};
