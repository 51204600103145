import type { ComponentMultiStyleConfig } from '@chakra-ui/theme';

export const Accordion: ComponentMultiStyleConfig = {
  parts: ['button'],
  baseStyle: {
    button: {
      _focus: {
        outline: 'none',
        boxShadow: 'initial',
      },
      _focusVisible: {
        boxShadow: 'inset 0 0 0 3px rgba(66, 153, 225, 0.6)',
      },
    },
  },
};
