export const textStyles = {
  xxl: {
    1: {
      fontSize: '32px',
      fontWeight: 'bold',
      lineHeight: '36px',
    },
  },

  xl: {
    1: {
      fontSize: '24px',
      fontWeight: 'bold',
      lineHeight: '32px',
    },
  },

  lg: {
    1: {
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '28px',
    },
  },

  md: {
    1: {
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '24px',
    },
    2: {
      fontSize: '16px',
      fontWeight: 'medium',
      lineHeight: '24px',
    },
  },

  sm: {
    1: {
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '20px',
    },
    2: {
      fontSize: '14px',
      fontWeight: 'medium',
      lineHeight: '20px',
    },
    3: {
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '20px',
    },
  },

  xs: {
    1: {
      fontSize: '12px',
      fontWeight: 'bold',
      lineHeight: '16px',
    },
    2: {
      fontSize: '12px',
      fontWeight: 'medium',
      lineHeight: '16px',
    },
  },
  xxs: {
    1: {
      fontSize: '10px',
      fontWeight: 'bold',
      lineHeight: '14px',
    },
    2: {
      fontSize: '10px',
      fontWeight: 'medium',
      lineHeight: '14px',
    },
  },
};
