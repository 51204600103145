import {
  Box,
  Button,
  Flex,
  GridItem,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Icon,
} from '@chakra-ui/react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from '@tanstack/react-router';
import logo from '../../assets/masterdata_logo.svg';
import { LaunchOutlined, ArrowDropDown } from '@mui/icons-material';

export const Header = () => {
  const navigate = useNavigate();

  const logout = async () => {
    const auth = getAuth();
    await signOut(auth);
  };

  const handleHomeButtonClick = () => {
    navigate({ to: `/app/manager/spots` });
  };

  return (
    <GridItem bg="base.0" area="header">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        paddingY="3"
        paddingLeft="4"
        paddingRight="6"
      >
        <Link onClick={handleHomeButtonClick}>
          <Image src={logo} alt="Loogia" height="32px" />
        </Link>
        {import.meta.env.MODE === 'dev' && <Text>DEVELOP</Text>}
        <Flex>
          <Box
            paddingY="1"
            paddingLeft="2"
            paddingRight="2.5"
            _hover={{ background: 'baseScrim.12' }}
            rounded="full"
          >
            <Link
              href="https://docs.google.com/forms/d/e/1FAIpQLSeLuICSu2vx8wmMKjwbOLbxG8RzzberwlnTWiYkFtOhnbGtnQ/viewform"
              isExternal
              rel="noopener noreferrer"
              _hover={{ textDecoration: 'none' }}
            >
              <Flex alignItems="center">
                <Icon boxSize={5} marginTop="0.5" marginRight="1">
                  <LaunchOutlined />
                </Icon>
                <Text>お問い合わせ</Text>
              </Flex>
            </Link>
          </Box>
          <Menu>
            <MenuButton
              paddingY="1"
              paddingLeft="2.5"
              paddingRight="0.5"
              as={Button}
              variant="link"
              color="base.6"
              fontWeight="normal"
              _active={{ color: 'base.6' }}
              _hover={{ background: 'baseScrim.12' }}
              rounded="full"
            >
              <Flex alignItems="center">
                {/* <Text>{organization?.name}</Text> */}
                <Text>テスト組織名</Text>
                <Icon>
                  <ArrowDropDown />
                </Icon>
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={logout} data-tracking-id="sign-out-button">
                ログアウト
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
    </GridItem>
  );
};
