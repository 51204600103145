import { Box, Text } from '@chakra-ui/react';
import { JSONSchema7 } from 'json-schema';

export interface SchemaDescriptionProps {
  meta?: Record<string, string | boolean>;
  schema?: JSONSchema7 | null;
}

export const SchemaDescription: React.FC<SchemaDescriptionProps> = ({
  meta,
  schema,
}) => {
  return Object.entries(meta || {})
    .filter(
      ([key, value]) =>
        value && (schema?.properties![key] as JSONSchema7).title,
    )
    .map(([key]) => (
      <Box
        key={key}
        border="1px solid"
        borderColor="base.3"
        borderRadius="2px"
        paddingX="8px"
        paddingY="2.5px"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        <Text textStyle="sm.2">
          {(schema?.properties![key] as JSONSchema7).type === 'boolean'
            ? (schema?.properties![key] as JSONSchema7).title
            : meta?.[key]}
        </Text>
      </Box>
    ));
};
