import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { selector } from './selector';
import { spotQueryKeys } from '../../../../api/spot/cache';
import { query } from '../../../../api/spot/queries';

export const useSpotDetailQuery = (userSpecifiedSpotId: string) => {
  return useSuspenseQuery(getSpotDetail(userSpecifiedSpotId));
};

export const getSpotDetail = (userSpecifiedSpotId: string) => {
  return queryOptions({
    queryKey: spotQueryKeys.spotDetail(userSpecifiedSpotId),
    queryFn: async () => query.fetchSpotDetail(userSpecifiedSpotId),
    select: ({ spot }) => selector(spot),
  });
};
