export const colors = {
  // Primary
  blue: {
    1: '#DFE8F7',
    2: '#8FA6CB',
    3: '#94B3E3',
    4: '#4681DE',
    5: '#2A68C9',
    6: '#1F4E96',
  },

  // Base
  base: {
    0: '#FFFFFF',
    1: '#F5F7FA',
    2: '#EBEDF0',
    3: '#E1E2E5',
    4: '#969799',
    5: '#55585C',
    6: '#1A1919',
  },

  baseScrim: {
    12: 'rgba(26, 25, 25, 0.12)',
    50: 'rgba(26, 25, 25, 0.5)',
  },

  // Danger
  red: {
    1: '#F5E0E4',
    2: '#CFA9AF',
    3: '#DC99A4',
    4: '#ED5E76',
    5: '#BA354B',
    6: '#872737',
  },

  // Warning
  yellow: {
    4: '#F2841C',
    5: '#C45200',
  },

  // Success
  green: {
    4: '#3D9877',
    5: '#01705C',
  },

  // Information
  purple: {
    4: '#6B61E0',
    5: '#4F47A5',
  },

  // Carrier
  carrier: {
    0: '#e731fe',
    1: '#c48d38',
    2: '#032250',
    3: '#C300EC',
    4: '#b817b3',
    5: '#0f6af1',
    6: '#F60074',
    7: '#0fc259',
    8: '#31cd21',
    9: '#fc82ce',
    10: '#d5722f',
    11: '#55b8bf',
    12: '#87ac1a',
    13: '#34628e',
    14: '#bf156f',
    15: '#a1ba0b',
    16: '#ab85ac',
    17: '#67011f',
    18: '#0d52ed',
    19: '#ebc52c',
    20: '#841089',
    21: '#966639',
    22: '#c784ef',
    23: '#49c620',
    24: '#a03eec',
    25: '#c78d39',
    26: '#e6632c',
    27: '#6ea6e8',
    28: '#b0731d',
    29: '#fb5d28',
    30: '#ad2499',
    31: '#8da3a1',
    32: '#5e355f',
    33: '#01bcde',
    34: '#730c1a',
    35: '#89d2c3',
    36: '#e0e40a',
    37: '#0bea34',
    38: '#07a071',
    39: '#00902B',
    40: '#3cb52c',
    41: '#7b7aa4',
    42: '#764a38',
    43: '#1df73e',
    44: '#15f648',
    45: '#cb4fa2',
    46: '#5fd482',
    47: '#7c15a2',
    48: '#ea56ee',
    49: '#be46fb',
    50: '#cf28f4',
    51: '#75008D',
    52: '#e65bbd',
    53: '#951b19',
    54: '#4a765d',
    55: '#977ef3',
    56: '#fa2337',
    57: '#893867',
    58: '#2b774a',
    59: '#4d2a1b',
    60: '#4e96e3',
    61: '#2bf3c0',
    62: '#c95f4a',
    63: '#53b7fe',
    64: '#8d7a99',
    65: '#1f3c09',
    66: '#2c45dd',
    67: '#57262f',
    68: '#0761d6',
    69: '#912554',
    70: '#10bbce',
    71: '#b99832',
    72: '#93cbf2',
    73: '#da9b95',
    74: '#bf98e0',
    75: '#16f2b1',
    76: '#71989f',
    77: '#5f97f6',
    78: '#27ce96',
    79: '#bb9664',
    80: '#719922',
    81: '#3d5c03',
    82: '#a33a61',
    83: '#fd9a95',
    84: '#6162cc',
    85: '#460f8c',
    86: '#8d8f6e',
    87: '#5480a5',
    88: '#00A0EB',
    89: '#171920',
    90: '#0adeae',
    91: '#21aaa4',
    92: '#f511b2',
    93: '#ef931d',
    94: '#37a40b',
    95: '#a41089',
    96: '#bab239',
    97: '#995200',
    98: '#2a49be',
    99: '#fa865b',
    100: '#60e5ba',
    101: '#557478',
    102: '#4c695e',
    103: '#c97ce6',
    104: '#f234d1',
    105: '#f65e58',
    106: '#16a1a3',
    107: '#56d68b',
    108: '#65f173',
    109: '#17f78c',
    110: '#bc1743',
    111: '#0b604a',
    112: '#22cae8',
    113: '#7b1c3d',
    114: '#b0ba2d',
    115: '#5d7379',
    116: '#3a1ab7',
    117: '#c82ae3',
    118: '#670f02',
    119: '#b04672',
    120: '#d5394d',
    121: '#5c1478',
    122: '#64a36b',
    123: '#867254',
    124: '#711a6c',
    125: '#7aa6f7',
    126: '#a075c6',
    127: '#1b207b',
    128: '#8b7c40',
    129: '#e4b220',
    130: '#c76249',
    131: '#2f8ef3',
    132: '#11fb61',
    133: '#832ef1',
    134: '#60a0b0',
    135: '#9b3c89',
    136: '#ad534c',
    137: '#c7065a',
    138: '#36e4be',
    139: '#14a6f3',
    140: '#3f1169',
    141: '#1ba26b',
    142: '#a0b8b8',
    143: '#3f9549',
    144: '#bc7047',
    145: '#9889e6',
    146: '#00608D',
    147: '#4a8388',
    148: '#a9c91b',
    149: '#190e98',
    150: '#8c2f64',
    151: '#f452d9',
    152: '#d86964',
    153: '#d5834f',
    154: '#9441d9',
    155: '#15669d',
    156: '#766905',
    157: '#4318d0',
    158: '#27d696',
    159: '#27e9b1',
    160: '#7a5660',
    161: '#a694f9',
    162: '#551622',
    163: '#21b05e',
    164: '#7ab5f2',
    165: '#81c031',
    166: '#1eae29',
    167: '#cfdc27',
    168: '#6532f1',
    169: '#22436d',
    170: '#f5b594',
    171: '#9bd46c',
    172: '#a23cc0',
    173: '#366dab',
    174: '#00F149',
    175: '#1eb816',
    176: '#069740',
    177: '#e3d64b',
    178: '#930045',
    179: '#5f729b',
    180: '#11e1e2',
    181: '#df662c',
    182: '#4f3d10',
    183: '#364376',
    184: '#1403c2',
    185: '#FF8900',
    186: '#074628',
    187: '#b77188',
    188: '#6d38b0',
    189: '#a3b92e',
    unassigned: '#909399',
  },

  // Depot
  depot: '#55585C',

  spot: {
    goto: '#FFF2CC',
  },
};
