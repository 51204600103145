import { SpotDetail } from '.';
import { useSpotDetail } from './hooks';
import { useRecoilValue } from 'recoil';
import { userValue } from '../../recoil/authorizedInfo';

export const SpotDetailContainer = () => {
  const { spotData, metaSchemaObject, mapRef, moveToTagetMapPlace, markers } =
    useSpotDetail();
  const user = useRecoilValue(userValue);

  return (
    <SpotDetail
      spotData={spotData}
      metaSchemaObject={metaSchemaObject}
      user={user ?? undefined}
      mapRef={mapRef}
      moveToTagetMapPlace={moveToTagetMapPlace}
      markers={markers}
    />
  );
};
