import { useParams } from '@tanstack/react-router';
import { MapRef } from 'react-map-gl';
import { useSpotDetailQuery } from './data/useSpotDetailQuery';
import { useSpotMetaDetailQuery } from './data/useSpotMetaDetailQuery';
import { useSpotMetaSchemaQuery } from './data/useSpotMetaSchemaQuery';
import { PingLocationType } from '../../components/map/types/MapPingType';
import { useRef, useCallback } from 'react';
import { MapPing } from '../../components/map';
import { SpotData } from './types/SpotData';

export const useSpotDetail = () => {
  const { spotId } = useParams({ strict: false }) as { spotId: string };

  const query = useSpotDetailQuery(spotId);
  const metaQuery = useSpotMetaDetailQuery(spotId);
  const metaSchemaQuery = useSpotMetaSchemaQuery();

  const spotData = { ...query.data, meta: metaQuery?.data };

  const markers = generateMarkers(spotData);

  const mapRef = useRef<MapRef>(null);
  const moveToTagetMapPlace = useCallback(
    ({ longitude, latitude }: { longitude: number; latitude: number }) => {
      if (!mapRef.current) {
        return;
      }
      mapRef.current?.panTo([longitude, latitude]);
    },
    [],
  );

  return {
    spotData: spotData,
    metaSchemaObject: metaSchemaQuery.data,
    markers,
    mapRef,
    moveToTagetMapPlace,
  };
};

export const generateMarkers = (spotData: SpotData) => {
  const locations = [
    {
      guidance: {
        geocode: {
          latitude: spotData?.representativeLocation.latitude,
          longitude: spotData?.representativeLocation.longitude,
        },
      },
      locationType: PingLocationType.REPRESENTITIVE_POSITION,
    },
    {
      guidance: spotData?.guidanceLocations[0],
      locationType: PingLocationType.GUIDANCE_LOCATION_1,
    },
    {
      guidance: spotData?.guidanceLocations[1],
      locationType: PingLocationType.GUIDANCE_LOCATION_2,
    },
    {
      guidance: spotData?.guidanceLocations[2],
      locationType: PingLocationType.GUIDANCE_LOCATION_3,
    },
    {
      guidance: spotData?.guidanceLocations[3],
      locationType: PingLocationType.GUIDANCE_LOCATION_4,
    },
    {
      guidance: spotData?.guidanceLocations[4],
      locationType: PingLocationType.GUIDANCE_LOCATION_5,
    },
    {
      guidance: spotData?.parkingLocation,
      locationType: PingLocationType.PARKING_LOCATION,
    },
  ];

  return locations.reduce<MapPing[]>((acc, { guidance, locationType }) => {
    if (guidance?.geocode) {
      acc.push({
        locationType,
        geocode: {
          lat: guidance.geocode.latitude!,
          lng: guidance.geocode.longitude!,
        },
      });
    }
    return acc;
  }, []);
};
