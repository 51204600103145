import { Box, Icon } from '@chakra-ui/react';
import { LocalParkingRounded } from '@mui/icons-material';

export const ParkingLocationIcon = () => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'carrier.88',
        borderRadius: '20%',
        boxSizing: 'content-box',
        height: '24px',
        width: '24px',
      }}
    >
      <Icon color="white" viewBox="0 0 16 16">
        <LocalParkingRounded />
      </Icon>
    </Box>
  );
};
