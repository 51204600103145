import { Marker } from 'react-map-gl';
import { Image } from '@chakra-ui/react';
import ActiveCurrentPosition from '../assets/ActiveCurrentPosition.svg';

export type ActiveCurrentPositionProps = {
  lat: number;
  lng: number;
};

export const ActiveCurrentPositionMarker: React.FC<
  ActiveCurrentPositionProps
> = ({ lat, lng }) => {
  return (
    <Marker latitude={lat} longitude={lng} anchor="bottom">
      <Image src={ActiveCurrentPosition} alt="ActiveCurrentPositionMarker" />
    </Marker>
  );
};
