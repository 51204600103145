import { useSuspenseQuery } from '@tanstack/react-query';
import { selector } from './selector';
import { spotQueryKeys } from '../../../../api/spot/cache';
import { query } from '../../../../api/spot/queries';

export const useSpotMetaDetailQuery = (userSpecifiedSpotId: string) => {
  return useSuspenseQuery({
    queryKey: spotQueryKeys.spotMetaDetail(userSpecifiedSpotId),
    queryFn: async () => query.fetchSpotMetaDetail(userSpecifiedSpotId),
    select: ({ meta }) => selector(meta),
  });
};
