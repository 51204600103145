import type { ComponentSingleStyleConfig } from '@chakra-ui/theme';

export const Textarea: ComponentSingleStyleConfig = {
  variants: {
    outline: {
      background: 'base.0',
      borderRadius: '4px',
      _focus: {
        borderColor: 'blue.5',
        borderWidth: '2px',
      },
      _hover: {
        borderColor: 'base.4',
      },
      _invalid: {
        borderColor: 'red.5',
        borderWidth: '2px',
      },
      _disabled: {
        opacity: 0.5,
        background: 'base.1',
        color: 'base.4',
      },
    },
  },
};
