import {
  FormLabel,
  FormControl,
  Input,
  Text,
  FormErrorMessage,
} from '@chakra-ui/react';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';

export interface LoginFieldProps {
  label: string;
  type: string;
  id: string;
  placeholder: string;
  errorMessage: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: UseFormWatch<any>;
}

export const LoginField: React.FC<LoginFieldProps> = ({
  label,
  type,
  id,
  placeholder,
  errorMessage,
  register,
  watch,
}) => {
  const watchValue = watch(id);
  return (
    <FormControl isInvalid={!!errorMessage}>
      <FormLabel>
        <Text textStyle="md.2">{label}</Text>
      </FormLabel>
      <FormErrorMessage>
        <Text textStyle="xs.2" color="red.5" paddingBottom="8px">
          {errorMessage}
        </Text>
      </FormErrorMessage>
      <Input
        type={type}
        id={id}
        placeholder={placeholder}
        defaultValue={watchValue}
        fontSize={{ base: 'sm', md: 'md', xl: 'lg' }}
        height={{ base: '48px', md: '36px', xl: '36px' }}
        {...register(id)}
      />
    </FormControl>
  );
};
