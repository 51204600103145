import { createFileRoute } from '@tanstack/react-router';
import { Login } from '../pages/Login';
import { Loading } from '../components/loading/Loading';
import { isMobile } from '../utils/mobileDetector';
import { DriverLogin } from '../pages/DriverLogin';

export const Route = createFileRoute('/')({
  component: () => {
    return isMobile() ? <DriverLogin /> : <Login />;
  },
  pendingComponent: Loading,
});
