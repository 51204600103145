import type { ComponentSingleStyleConfig } from '@chakra-ui/theme';
import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  opacity: 1,
};

export const Divider: ComponentSingleStyleConfig = {
  baseStyle,
};
