import { useState, useMemo } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { auth } from '../../firebase';
import { useForm } from 'react-hook-form';

interface ErrorMessages {
  email: Record<string, string>;
  password: Record<string, string>;
  button: Record<string, string>;
}

const errorMessages: ErrorMessages = {
  email: {
    'auth/invalid-email': '無効なIDです',
    'auth/user-not-found': 'ユーザーが見つかりません',
  },
  password: {
    'auth/wrong-password': 'パスワードが間違っています',
    'auth/missing-password': 'パスワードが未入力です',
  },
  button: {
    'auth/too-many-requests': '時間をおいて再度ログインしてください',
    'auth/user-disabled': '無効なアカウントです',
    'unauthorized-ip-address': '無効なIPアドレスです',
  },
};

const getErrorMessage = (
  code: string | null,
  errors: Record<string, string>,
): string => (code ? errors[code] || '' : '');

export const useLoginForm = () => {
  const [errorCode, setErrorCode] = useState<string | null>(null);

  const { register, handleSubmit, watch } = useForm<{
    email: string;
    password: string;
  }>();

  const emailErrorMessage = useMemo(
    () => getErrorMessage(errorCode, errorMessages.email),
    [errorCode],
  );

  const passwordErrorMessage = useMemo(
    () => getErrorMessage(errorCode, errorMessages.password),
    [errorCode],
  );

  const buttonErrorMessage = useMemo(() => {
    let message = getErrorMessage(errorCode, errorMessages.button);
    if (errorCode && !emailErrorMessage && !passwordErrorMessage && !message) {
      message = 'ログインできませんでした';
    }
    return message;
  }, [errorCode, emailErrorMessage, passwordErrorMessage]);

  const login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setErrorCode(null);
    } catch (error) {
      let code = 'unknown';
      if (error instanceof FirebaseError) {
        if (error.message.includes('Unauthorized IP Address')) {
          code = 'unauthorized-ip-address';
        } else {
          code = error.code;
        }
      }
      setErrorCode(code);
    }
  };

  return {
    emailErrorMessage,
    passwordErrorMessage,
    buttonErrorMessage,
    login,
    register,
    watch,
    handleSubmit,
  };
};
