import { apiClient } from '../apiClient';
import { Spot } from './types/spot';
import { SpotMeta } from './types/spotMeta';
import type { JSONSchema7 } from 'json-schema';

export type FetchSpotListResponse = {
  spots: Spot[];
};
export type FetchSpotResponse = {
  spot: Spot;
};
export type FetchSpotMetaResponse = {
  meta: SpotMeta | null;
};
export type FetchSpotMetaSchemaResponse = {
  schema: JSONSchema7 | null;
};

export const query = {
  async fetchSpotList() {
    return apiClient<FetchSpotListResponse>('get', '/spots');
  },

  async fetchSpotDetail(userSpecifiedSpotId: string) {
    return apiClient<FetchSpotResponse>('get', `/spots/${userSpecifiedSpotId}`);
  },

  async fetchSpotMetaDetail(userSpecifiedSpotId: string) {
    return apiClient<FetchSpotMetaResponse>(
      'get',
      `/spots/${userSpecifiedSpotId}/meta`,
    );
  },

  async fetchSpotMetaSchemaLatest() {
    return apiClient<FetchSpotMetaSchemaResponse>(
      'get',
      '/spotMetaSchema/latest',
    );
  },
};
