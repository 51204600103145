import { selector } from 'recoil';
import { authorizedInfoValue } from './authorizedInfoSelector';

export const userValue = selector({
  key: 'authorizedUser',
  get({ get }) {
    const result = get(authorizedInfoValue);

    return result.isLoggedIn ? result.user : null;
  },
});
