import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { RecoilRoot } from 'recoil';

// @TODO: ゼンリン地図のlambdaがcors対応になったら、以下を解除する
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js', { scope: './', type: 'module' })
      .then(
        (registration) => {
          // 登録成功
          console.log(
            'ServiceWorker registration successful with scope: ',
            registration.scope,
          );
        },
        (err) => {
          // 登録失敗
          console.log('ServiceWorker registration failed: ', err);
        },
      );
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <RecoilRoot>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </RecoilRoot>,
);
