import type { ComponentMultiStyleConfig } from '@chakra-ui/theme';

export const Table: ComponentMultiStyleConfig = {
  parts: ['table'],
  baseStyle: {
    table: {
      border: '1px',
      borderColor: 'base.3',
      borderCollapse: 'separate',
      borderSpacing: 0,
      borderRadius: '4px',
      padding: '12px',
      background: 'base.0',
    },
  },
  variants: {
    simple: {
      th: {
        color: 'base.6',
        borderBottom: '1px',
        borderColor: 'base.3',
      },
      td: {
        borderBottom: '1px',
        borderColor: 'base.3',
      },
    },
  },
  sizes: {
    md: {
      th: {
        paddingX: '16px',
        paddingY: '4px',
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: '20px',
      },
      td: {
        paddingX: '16px',
        paddingY: '8px',
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '20px',
      },
    },
  },
};
