import type { ComponentMultiStyleConfig } from '@chakra-ui/theme';

export const NumberInput: ComponentMultiStyleConfig = {
  parts: ['field'],
  variants: {
    outline: {
      field: {
        background: 'base.0',
        color: 'base.6',
        fontFamily: 'Noto Sans JP',
      },
    },
  },
};
