import type { ComponentSingleStyleConfig } from '@chakra-ui/theme';
import type { SystemStyleFunction } from '@chakra-ui/theme-tools';

const variantSolid: SystemStyleFunction = ({ colorScheme }) => ({
  bg: `${colorScheme}.5`,
});

const variantOutline: SystemStyleFunction = ({ colorScheme }) => ({
  color: `${colorScheme}.5`,
});

const variantOutlineSquare: SystemStyleFunction = ({ colorScheme }) => ({
  color: colorScheme === 'base' ? `${colorScheme}.6` : `${colorScheme}.5`,
  border: '1px',
  borderColor: colorScheme === 'base' ? `${colorScheme}.6` : `${colorScheme}.5`,
  borderRadius: 'none',
});

const variantSubtle: SystemStyleFunction = ({ colorScheme }) => {
  const bgColor =
    colorScheme === 'base' ? `${colorScheme}.2` : `${colorScheme}.1`;

  return {
    bg: bgColor,
    color: `${colorScheme}.5`,
  };
};

export const Badge: ComponentSingleStyleConfig = {
  variants: {
    solid: variantSolid,
    outline: variantOutline,
    outlineSquare: variantOutlineSquare,
    subtle: variantSubtle,
  },
};
