import * as React from 'react';
import { Text } from '@chakra-ui/react';
import { BaseLocation } from '../BaseLocation';
import { Geocode } from '../../types/SpotData';
import {
  type GuidanceLocationIconProps,
  GuidanceLocationIcon,
} from '../../../../components/icons/GuidanceLocationIcon';

interface LocationFieldsProps {
  guidanceIndex: GuidanceLocationIconProps['guidanceIndex'];
  title: string;
  travelDuration?: number;
  geocode?: Geocode;
  panTo: ({ longitude, latitude }: Geocode) => void;
}

export const GuidanceLocation: React.FC<LocationFieldsProps> = ({
  guidanceIndex,
  title,
  travelDuration,
  geocode,
  panTo,
}) => {
  return (
    <BaseLocation
      icon={<GuidanceLocationIcon guidanceIndex={guidanceIndex} />}
      title={title}
      geocode={geocode}
      panTo={panTo}
      children={
        geocode && (
          <Text textStyle={'sm.2'}>
            代表位置までの片道の移動時間 {travelDuration}分
          </Text>
        )
      }
    />
  );
  // return (
  //   <Stack marginBottom="16px" gap="8px">
  //     <Stack gap="4px">
  //       <Flex flexDir="row" gap="4px" alignItems="center">
  //         <Box
  //           sx={{
  //             display: 'inline-flex',
  //             alignItems: 'center',
  //             justifyContent: 'center',
  //             backgroundColor: locationType.backgroundColor,
  //             borderRadius: '20%',
  //             boxSizing: 'content-box',
  //             height: '24px',
  //             width: '24px',
  //           }}
  //         >
  //           <Icon color="white" viewBox="16px">
  //             {locationType.icon}
  //           </Icon>
  //         </Box>
  //         <Text textStyle="sm.1" whiteSpace={geocode ? '' : 'nowrap'}>
  //           {locationType.ja} {geocode ? '' : '(未指定)'}
  //         </Text>
  //         {geocode && (
  //           <Flex
  //             height="24px"
  //             width="24px"
  //             justifyContent="center"
  //             alignItems="center"
  //           >
  //             <Icon boxSize="12px" color="base.5">
  //               <GpsFixed
  //                 onClick={() =>
  //                   moveToTagetMapPlace({
  //                     longitude: geocode.longitude || 0,
  //                     latitude: geocode.latitude || 0,
  //                   })
  //                 }
  //               />
  //             </Icon>
  //           </Flex>
  //         )}
  //       </Flex>
  //       {geocode && (
  //         <Box>
  //           <Text textStyle="xs.2" color="base.4">
  //             {`${geocode.latitude},${geocode.longitude}`}
  //           </Text>
  //         </Box>
  //       )}
  //     </Stack>
  //     {geocode && LocationType.PARKING_LOCATION !== locationType && (
  //       <Text textStyle={'sm.2'}>
  //         代表位置までの片道の移動時間 {travelDuration}分
  //       </Text>
  //     )}
  //   </Stack>
  // );
};
