import * as React from 'react';
import { Box, Flex, Text, Stack, Icon } from '@chakra-ui/react';
import { GpsFixed } from '@mui/icons-material';
import { Geocode } from '../../types/SpotData';
import { truncateToDecimalPlaces } from '../../../../utils/truncateToDecimalPlaces';
import { isMobile } from '../../../../utils/mobileDetector';

interface BaseLocationProps {
  icon: React.ReactNode;
  title: string;
  geocode?: Geocode;
  panTo?: ({ longitude, latitude }: Geocode) => void;
  children?: React.ReactNode;
}

export const BaseLocation: React.FC<BaseLocationProps> = ({
  icon,
  title,
  geocode,
  panTo,
  children,
}) => {
  return (
    <Stack marginBottom="16px" gap="8px">
      <Stack gap="4px">
        <Flex flexDir="row" gap="4px" alignItems="center">
          {icon}
          <Text textStyle="sm.1" whiteSpace={geocode ? '' : 'nowrap'}>
            {title}
          </Text>
          {geocode && (
            <Flex
              height={`${isMobile() ? '48' : '24'}px`}
              width={`${isMobile() ? '48' : '24'}px`}
              justifyContent="center"
              alignItems="center"
              onClick={() =>
                panTo &&
                panTo({
                  longitude: geocode.longitude || 0,
                  latitude: geocode.latitude || 0,
                })
              }
            >
              <Icon boxSize={`${isMobile() ? '24' : '12'}px`} color="base.5">
                <GpsFixed />
              </Icon>
            </Flex>
          )}
        </Flex>
        {geocode && (
          <Box>
            <Text textStyle="xs.2" color="base.4">
              {`${truncateToDecimalPlaces(geocode.latitude)},${truncateToDecimalPlaces(geocode.longitude)}`}
            </Text>
          </Box>
        )}
      </Stack>
      {children}
    </Stack>
  );
};
