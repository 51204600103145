import React from 'react';
import { Geocode } from '../../types/SpotData';
import { BaseLocation } from '../BaseLocation';
import { RepresentativeLocationIcon } from '../../../../components/icons/RepresentativeLocationIcon';

interface RepresentativeLocationProps {
  geocode: Geocode;
  panTo: ({ longitude, latitude }: Geocode) => void;
}

export const RepresentativeLocation: React.FC<RepresentativeLocationProps> = ({
  geocode,
  panTo,
}) => {
  return (
    <BaseLocation
      icon={<RepresentativeLocationIcon />}
      title="代表位置"
      geocode={geocode}
      panTo={panTo}
    />
  );
};
