export const spotQueryKeys = {
  all: ['spots'] as const,
  spotlist: () => [...spotQueryKeys.all, 'list'] as const,
  spotDetail: (userSpecifiedSpotId: string) =>
    [...spotQueryKeys.all, 'detail', userSpecifiedSpotId] as const,
  spotMetaDetail: (userSpecifiedSpotId: string) =>
    [...spotQueryKeys.all, 'meta', 'detail', userSpecifiedSpotId] as const,
  spotMetaSchemaLatest: () =>
    [...spotQueryKeys.all, 'meta', 'schema', 'latest'] as const,
};
