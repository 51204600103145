import { useRecoilValue } from 'recoil';
import {
  driverAppSpotIdValue,
  isLoggedInValue,
  userValue,
} from '../../recoil/authorizedInfo';
import { Navigate } from '@tanstack/react-router';
import { Loading } from '../../components/loading/Loading';
export const DriverLogin = () => {
  const isLoggedIn = useRecoilValue(isLoggedInValue);
  const user = useRecoilValue(userValue);
  const driverAppSpotId = useRecoilValue(driverAppSpotIdValue);

  if (isLoggedIn) {
    if (user?.role !== 'driver') {
      throw new Error('unauthorized user');
    }
    return <Navigate to={`/app/driver/spots/${driverAppSpotId}/detail`} />;
  }

  // mobileにログイン画面を表示してredirectするには違和感があったので、mobileだけログイン画面をローディング画面で表示する
  return <Loading />;
};
