import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

// recoilを使用したGlobalStateの永続化
// sessionStorage: ウェブブラウザのタブが開かれている間だけデータを保存する
// localStorage  : ウェブブラウザのタブが閉じてもデータを保存する
const { persistAtom } = recoilPersist({
  key: 'recoilPersistSpotId',
  storage: typeof window === 'undefined' ? undefined : window.sessionStorage,
});

export const driverAppSpotIdAtom = atom<string | null>({
  key: 'driverAppSpotIdAtom',
  default: null,
  effects_UNSTABLE: [persistAtom],
});
