import { Box, Image } from '@chakra-ui/react';
import RepresentativeLocationPing from '../assets/RepersentitivePosition.svg';
import GuidanceLocation1Ping from '../assets/GuidanceLocation1Ping.svg';
import GuidanceLocation2Ping from '../assets/GuidanceLocation2Ping.svg';
import GuidanceLocation3Ping from '../assets/GuidanceLocation3Ping.svg';
import GuidanceLocation4Ping from '../assets/GuidanceLocation4Ping.svg';
import GuidanceLocation5Ping from '../assets/GuidanceLocation5Ping.svg';
import ParkingPing from '../assets/ParkingPing.svg';
import GuidanceLocation1GpsFixPing from '../assets/GuidanceLocation1GpsFixPing.svg';
import GuidanceLocation2GpsFixPing from '../assets/GuidanceLocation2GpsFixPing.svg';
import GuidanceLocation3GpsFixPing from '../assets/GuidanceLocation3GpsFixPing.svg';
import GuidanceLocation4GpsFixPing from '../assets/GuidanceLocation4GpsFixPing.svg';
import GuidanceLocation5GpsFixPing from '../assets/GuidanceLocation5GpsFixPing.svg';
import RepersentitivePositionGpsFixPing from '../assets/RepersentitivePositionGpsFixPing.svg';
import ParkingGpsFixPing from '../assets/ParkingGpsFixPing.svg';

export enum PingLocationType {
  PARKING_LOCATION = 'PARKING_LOCATION',
  REPRESENTITIVE_POSITION = 'REPRESENTITIVE_POSITION',
  GUIDANCE_LOCATION_1 = 'GUIDANCE_LOCATION_1',
  GUIDANCE_LOCATION_2 = 'GUIDANCE_LOCATION_2',
  GUIDANCE_LOCATION_3 = 'GUIDANCE_LOCATION_3',
  GUIDANCE_LOCATION_4 = 'GUIDANCE_LOCATION_4',
  GUIDANCE_LOCATION_5 = 'GUIDANCE_LOCATION_5',
}

export const LocationPing = {
  [PingLocationType.PARKING_LOCATION]: {
    icon: (
      <Box>
        <Image src={ParkingPing} alt="ParkingPing" />
      </Box>
    ),

    gpsFixIcon: (
      <Box>
        <Image src={ParkingGpsFixPing} alt="ParkingGpsFixPing" />
      </Box>
    ),
    typeName: '駐車位置',
  },
  [PingLocationType.REPRESENTITIVE_POSITION]: {
    icon: (
      <Box>
        <Image
          src={RepresentativeLocationPing}
          alt="RepresentativeLocationPing"
        />
      </Box>
    ),
    gpsFixIcon: (
      <Box>
        <Image
          src={RepersentitivePositionGpsFixPing}
          alt="RepersentitivePositionGpsFixPing"
        />
      </Box>
    ),
    typeName: '代表位置',
  },
  [PingLocationType.GUIDANCE_LOCATION_1]: {
    icon: (
      <Box>
        <Image src={GuidanceLocation1Ping} alt="GuidanceLocation1Ping" />
      </Box>
    ),
    gpsFixIcon: (
      <Box>
        <Image
          src={GuidanceLocation1GpsFixPing}
          alt="GuidanceLocation1GpsFixPing"
        />
      </Box>
    ),
    typeName: '案内希望位置',
  },
  [PingLocationType.GUIDANCE_LOCATION_2]: {
    icon: (
      <Box>
        <Image src={GuidanceLocation2Ping} alt="GuidanceLocation2Ping" />
      </Box>
    ),
    gpsFixIcon: (
      <Box>
        <Image
          src={GuidanceLocation2GpsFixPing}
          alt="GuidanceLocation2GpsFixPing"
        />
      </Box>
    ),
    typeName: '案内希望位置',
  },
  [PingLocationType.GUIDANCE_LOCATION_3]: {
    icon: (
      <Box>
        <Image src={GuidanceLocation3Ping} alt="GuidanceLocation3Ping" />
      </Box>
    ),
    gpsFixIcon: (
      <Box>
        <Image
          src={GuidanceLocation3GpsFixPing}
          alt="GuidanceLocation3GpsFixPing"
        />
      </Box>
    ),
    typeName: '案内希望位置',
  },
  [PingLocationType.GUIDANCE_LOCATION_4]: {
    icon: (
      <Box>
        <Image src={GuidanceLocation4Ping} alt="GuidanceLocation4Ping" />
      </Box>
    ),
    gpsFixIcon: (
      <Box>
        <Image
          src={GuidanceLocation4GpsFixPing}
          alt="GuidanceLocation4GpsFixPing"
        />
      </Box>
    ),
    typeName: '案内希望位置',
  },
  [PingLocationType.GUIDANCE_LOCATION_5]: {
    icon: (
      <Box>
        <Image src={GuidanceLocation5Ping} alt="GuidanceLocation5Ping" />
      </Box>
    ),
    gpsFixIcon: (
      <Box>
        <Image
          src={GuidanceLocation5GpsFixPing}
          alt="GuidanceLocation5GpsFixPing"
        />
      </Box>
    ),
    typeName: '案内希望位置',
  },
};
