import type { ComponentMultiStyleConfig } from '@chakra-ui/theme';

export const Menu: ComponentMultiStyleConfig = {
  parts: ['button', 'list', 'item'],
  baseStyle: {
    button: {
      padding: '10px 16px',
      borderRadius: '6px',
      backgroundColor: 'base.1',
      color: 'base.6',
      border: 'none',
      fontWeight: 700,
      _hover: {
        bg: 'base.3',
      },
      _active: {
        bg: 'base.3',
      },
      _focus: {
        outline: 'none',
        boxShadow: 'none',
      },
      _focusVisible: {
        outlineColor: 'blue.3',
        outlineStyle: 'solid',
        outlineWidth: '2px',
      },
    },
    list: {
      marginTop: '-8px',
    },
    item: {
      _hover: {
        backgroundColor: 'base.3',
      },
    },
  },
};
