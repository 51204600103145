import type { ComponentMultiStyleConfig } from '@chakra-ui/theme';

export const Popover: ComponentMultiStyleConfig = {
  parts: ['content', 'button'],
  baseStyle: {
    content: {
      borderRadius: '4px',
      _focus: {
        outline: 'none',
        boxShadow: 'none',
      },
    },
    button: {
      _focus: {
        outline: 'none',
        boxShadow: 'none',
      },
      _focusVisible: {
        outlineColor: 'blue.3',
        outlineStyle: 'solid',
        outlineWidth: '2px',
      },
    },
  },
  variants: {
    responsive: {
      popper: {
        maxWidth: 'unset',
        width: 'unset',
      },
    },
  },
};
