import type { ComponentMultiStyleConfig } from '@chakra-ui/theme';
import type { SystemStyleObject } from '@chakra-ui/theme-tools';
import { textStyles } from '../../foundations/textStyles';

const baseStyle: SystemStyleObject = {
  preview: {
    _focus: {
      outline: 'none',
      boxShadow: 'none',
    },
    _focusVisible: {
      outlineColor: 'blue.3',
      outlineStyle: 'solid',
      outlineWidth: '2px',
    },
    _empty: {
      display: 'none',
    },
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    // TextStyleをそのまま設定しても反映されないのでimportして対応
    ...textStyles.sm[3],
  },
  textarea: {
    borderWidth: '1px',
    borderColor: 'base.3',
    borderStyle: 'solid',
    ...textStyles.sm[3],
  },
};

export const Editable: ComponentMultiStyleConfig = {
  parts: ['preview', 'textarea'],
  baseStyle,
};
