import { Flex, Spinner } from '@chakra-ui/react';

export const Loading: React.FC = () => {
  return (
    <Flex justifyContent="center" alignItems="center" height="100%">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="base.4"
        color="blue.5"
        size="xl"
        data-testid="loading-spinner"
      />
    </Flex>
  );
};
