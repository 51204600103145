import type { ComponentMultiStyleConfig } from '@chakra-ui/theme';
import type { PartsStyleFunction } from '@chakra-ui/theme-tools';

const colorMap = new Map([
  ['info', 'purple.5'],
  ['warning', 'yellow.5'],
  ['error', 'red.5'],
  ['success', 'green.5'],
]);

const fadedColorMap = new Map([
  ['info', 'rgba(79, 71, 165, 0.15)'],
  ['warning', 'rgba(242, 132, 28, 0.15)'],
  ['error', 'rgba(186, 53, 75, 0.15)'],
  ['success', 'rgba(1, 112, 92, 0.15)'],
]);

const variantSubtle: PartsStyleFunction = ({ status, colorScheme }) => {
  const iconColor = colorMap.get(status) ?? colorScheme;
  return {
    container: {
      bg: 'base.0',
    },
    icon: {
      color: iconColor,
    },
  };
};

const variantSolid: PartsStyleFunction = ({ status, colorScheme }) => {
  const bgColor = colorMap.get(status) ?? colorScheme;
  return {
    container: {
      bg: bgColor,
    },
  };
};

const variantFaded: PartsStyleFunction = ({ status, colorScheme }) => {
  const color = colorMap.get(status) ?? colorScheme;
  const bgColor = fadedColorMap.get(status) ?? colorScheme;
  return {
    container: {
      bg: bgColor,
    },
    icon: {
      color,
    },
    title: {
      textColor: color,
    },
    description: {
      textColor: color,
    },
  };
};

export const Alert: ComponentMultiStyleConfig = {
  parts: ['title', 'description', 'container', 'icon'],
  variants: {
    subtle: variantSubtle,
    solid: variantSolid,
    faded: variantFaded,
  },
};
