import { useSuspenseQuery } from '@tanstack/react-query';
import { selector } from './selector';
import { spotQueryKeys } from '../../../../api/spot/cache';
import { query } from '../../../../api/spot/queries';

export const useSpotMetaSchemaQuery = () => {
  return useSuspenseQuery({
    queryKey: spotQueryKeys.spotMetaSchemaLatest(),
    queryFn: async () => query.fetchSpotMetaSchemaLatest(),
    select: ({ schema }) => selector(schema),
  });
};
