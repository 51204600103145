import { FetchSpotResponse } from '../../../../api/spot/queries';
import { MAX_GUIDANCE_LOCATION_LENGTH } from '../../../../utils/constants/common';
import { SpotData } from '../../types/SpotData';

export const selector = (spot: FetchSpotResponse['spot']): SpotData => ({
  id: spot.id,
  displayName: spot.name,
  postalCodeLeft: spot.postalCode
    ? spot.postalCode.replace('-', '').slice(0, 3)
    : '',
  postalCodeRight: spot.postalCode
    ? spot.postalCode.replace('-', '').slice(3)
    : '',
  prefecture: spot.address.prefecture,
  street: spot.address.street,
  building: spot.address.building,
  representativeLocation: {
    latitude: spot.geocode.latitude,
    longitude: spot.geocode.longitude,
  },
  guidanceLocations: generateGuidacneLocations(spot.guidanceLocations),
  parkingLocation: generateParkingLocation(spot.otherLocations),
  createdAt: spot.createdAt,
  updatedAt: spot.updatedAt,
});

const generateGuidacneLocations = (
  guidanceLocations: FetchSpotResponse['spot']['guidanceLocations'],
) => {
  if (!guidanceLocations || !guidanceLocations.length) {
    return [];
  }

  const convertedGuidanceLocations = guidanceLocations.map((location) => ({
    geocode: location.geocode,
    travelDuration: location.travelDuration
      ? Math.round((location.travelDuration / 60) * 10) / 10
      : 0,
    uTurnCost: location.uTurnCost,
  }));

  return convertedGuidanceLocations.length < MAX_GUIDANCE_LOCATION_LENGTH
    ? convertedGuidanceLocations.concat(
        Array(
          MAX_GUIDANCE_LOCATION_LENGTH - convertedGuidanceLocations.length,
        ).fill({
          geocode: undefined,
        }),
      )
    : convertedGuidanceLocations;
};

const generateParkingLocation = (
  otherLocations: FetchSpotResponse['spot']['otherLocations'],
): SpotData['parkingLocation'] => {
  if (!otherLocations?.length) {
    return undefined;
  }
  const parkingLocation = otherLocations.find(
    (location) => location.type === 'parkingLocation',
  );

  return parkingLocation ? { geocode: parkingLocation.geocode } : undefined;
};
