/**
 * Foundations/index.ts
 *
 * コンポーネント以外のテーマ設定についてオーバーライドします。
 * 各項目について、オーバーライドするオブジェクトを別ファイルにて定義し、
 * このファイルにimportしてください。
 *
 * オーバーライドできるテーマ設定については、以下を参照してください。
 * https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/foundations
 */

import { typography } from './typography';
import { colors } from './colors';
import { textStyles } from './textStyles';

export const foundations = {
  ...typography,
  colors,
  textStyles,
};
