import {
  type Auth,
  getAuth,
  signOut,
  type User as FirebaseUser,
} from 'firebase/auth';
import { selector } from 'recoil';
import { isLoggedInAtom } from './atom';

export type User = {
  uid: string;
  email: string;
  role: string;
  organizationId: string;
  enableZenrin: boolean;
};

type Organization = {
  id: string;
  name: string;
};

export type AuthorizedInfo =
  | {
      isLoggedIn: true;
      user: User;
      organization: Organization;
    }
  | { isLoggedIn: false };

export const authorizedInfoValue = selector({
  key: 'authorizedInfoValue',
  async get({ get }): Promise<AuthorizedInfo> {
    const isLoggedIn = get(isLoggedInAtom);
    const auth = getAuth();

    try {
      const result = await (isLoggedIn ? login(auth) : logout(auth));

      return result;
    } catch (error) {
      console.error(error);
      return { isLoggedIn: false };
    }
  },
});

const login = async (auth: Auth): Promise<AuthorizedInfo> => {
  const { userInfo, token } = await getAuthorizedInfo(auth.currentUser);
  const organization =
    userInfo.role === 'manager'
      ? await getManagerOrganization(token)
      : await getDriverOrganization(token);

  const enableZenrin =
    userInfo.role === 'driver' ? await getDriverInfo(token) : false;
  return {
    isLoggedIn: true,
    user: {
      email: userInfo.email,
      uid: userInfo.user_id,
      role: userInfo.role,
      organizationId: userInfo.organizationId,
      enableZenrin,
    },
    organization: {
      id: organization.id,
      name: organization.name,
    },
  };
};

const logout = async (auth: Auth): Promise<AuthorizedInfo> => {
  if (auth.currentUser) {
    await signOut(auth);
  }

  return { isLoggedIn: false };
};

const getAuthorizedInfo = async (firebaseUser: FirebaseUser | null) => {
  if (!firebaseUser) {
    throw new Error('ユーザが見つかりませんでした。');
  }

  const result = await firebaseUser.getIdTokenResult(true);

  return {
    userInfo: result.claims as {
      email: string;
      uid: string;
      user_id: string;
      role: string;
      organizationId: string;
    },
    token: result.token,
  };
};

const getDriverOrganization = async (
  token: string,
): Promise<{ name: string; id: string; disableMixpanel: boolean }> => {
  const result = await fetch(
    `${import.meta.env.VITE_LOOGIA_PLANNING_DRIVER_API_URL}/organization`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (!result.ok) {
    throw new Error('組織情報の取得に失敗しました。');
  }

  return result.json() as unknown as {
    name: string;
    id: string;
    disableMixpanel: boolean;
  };
};

const getDriverInfo = async (token: string): Promise<boolean> => {
  const result = await fetch(
    `${import.meta.env.VITE_LOOGIA_PLANNING_DRIVER_API_URL}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (!result.ok) {
    throw new Error('ドライバー情報の取得に失敗しました。');
  }
  const info = await result.json();

  return info?.permissions?.enableZenrin ?? false;
};

const getManagerOrganization = async (
  token: string,
): Promise<{ name: string; id: string; disableMixpanel: boolean }> => {
  const result = await fetch(
    `${import.meta.env.VITE_LOOGIA_PLANNING_BROWSER_API_URL}/organization`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (!result.ok) {
    throw new Error('組織情報の取得に失敗しました。');
  }

  return result.json() as unknown as {
    name: string;
    id: string;
    disableMixpanel: boolean;
  };
};
