import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getAuthToken } from '../../firebase';

export type BrowserAPIError = {
  status: number;
  message: string;
  details?: Array<string>;
};

export const isBrowserAPIError = (value: unknown): value is BrowserAPIError => {
  if (typeof value === 'object' && value !== null) {
    const obj = value as Record<string, unknown>;
    return (
      typeof obj.status === 'number' &&
      typeof obj.message === 'string' &&
      (obj.details === undefined ||
        (Array.isArray(obj.details) &&
          obj.details.every((item) => typeof item === 'string')))
    );
  }
  return false;
};

export const apiClient = async <R, T = Record<string, never>>(
  method: 'get' | 'post' | 'put' | 'patch' | 'delete',
  url: string,
  body?: T,
  config?: AxiosRequestConfig,
): Promise<R> => {
  const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
  });

  try {
    const token = await getAuthToken();
    const headers = {
      ...config?.headers,
      Authorization: token,
      'Content-Type': 'application/json',
    };

    let response: AxiosResponse<R>;
    if (method == 'get' || method == 'delete') {
      response = await axiosInstance[method](url, {
        ...config,
        headers,
      });
    } else {
      response = await axiosInstance[method](url, body, {
        ...config,
        headers,
      });
    }

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      const errorResponse: BrowserAPIError = {
        status: error.response?.status ? error.response?.status : 500,
        message: error.response?.data.message,
        details: error.response?.data.detail,
      };

      throw errorResponse;
    }

    throw error;
  }
};
