/**
 * Styles.ts
 *
 * グローバルスタイルのテーマ設定を定義します。
 *
 * 記述例については、以下を参照してください。
 * https://chakra-ui.com/docs/styled-system/theming/customize-theme#customizing-global-styles
 */

import { type Styles } from '@chakra-ui/theme-tools';

export const styles: Styles = {
  global: {
    // ResetCSSで適用したくないCSSプロパティを上書き
    // https://www.npmjs.com/package/@chakra-ui/css-reset
    html: {
      '-webkit-font-smoothing': 'auto',
      '-moz-osx-font-smoothing': 'auto',
      height: '100%',
      width: '100%',
      minWidth: '100%',
      boxSizing: 'border-box',
    },
    '[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button':
      {
        '-webkit-appearance': 'auto !important',
      },

    body: {
      color: 'base.6',
      bg: 'base.1',
      height: '100%',
      width: '100%',
      minWidth: '100%',
      boxSizing: 'border-box',
      overflow: 'scroll',
    },

    '*::placeholder': {
      color: 'base.4',
    },

    '*, *::before, &::after': {
      borderColor: 'base.3',
    },
  },
};
