import { createFileRoute } from '@tanstack/react-router';
import { SpotDetailContainer } from '../../../../../../pages/SpotDetail/container';
import { isBrowserAPIError } from '../../../../../../api/apiClient';
import { Loading } from '../../../../../../components/loading/Loading';

export const Route = createFileRoute(
  '/app/_driverloggedin/driver/spots/$spotId/detail',
)({
  component: SpotDetailContainer,
  pendingComponent: Loading,
  errorComponent: (errorComponentProps) => {
    const error = errorComponentProps.error;

    if (isBrowserAPIError(error)) {
      return (
        <>
          <div>{`error: ${error.message}`}</div>
          <br />
          <div>そのまま閉じるボタンを押してください</div>
        </>
      );
    }

    return (
      <>
        <div>{`error: ${(error as Error).message}`}</div>
        <br />
        <div>そのまま閉じるボタンを押してください</div>
      </>
    );
  },
});
