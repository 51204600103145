import type { ComponentMultiStyleConfig } from '@chakra-ui/theme';

export const Tag: ComponentMultiStyleConfig = {
  parts: ['container'],
  variants: {
    solid: {
      container: {
        background: 'base.1',
        color: 'base.6',
      },
    },
    outline: {
      container: {
        background: 'base.0',
        color: 'base.6',
        boxShadow: 'inset 0 0 0px 1px var(--chakra-colors-base-3)',
      },
    },
  },
  sizes: {
    sm: {
      container: {
        borderRadius: '4px',
      },
    },
    md: {
      container: {
        borderRadius: '4px',
      },
    },
    lg: {
      container: {
        borderRadius: '4px',
      },
    },
  },
  defaultProps: {
    variant: 'solid',
  },
};
