import { createRootRouteWithContext } from '@tanstack/react-router';
import { isBrowserAPIError } from '../api/apiClient';
import { Error404, Error500 } from '../components/errors';
import { QueryClient } from '@tanstack/react-query';
import { isMobile } from '../utils/mobileDetector';

export const Route = createRootRouteWithContext<{ queryClient: QueryClient }>()(
  {
    errorComponent: (errorComponentProps) => {
      const error: unknown = errorComponentProps.error;

      if (isBrowserAPIError(error)) {
        if (error?.status >= 500) {
          return <Error500 />;
        }
        return <div>Global error page: {error.message}</div>;
      }

      if (isMobile()) {
        return (
          <>
            <div>Global error page: {(error as Error).message}</div> <br />
            <div>そのまま閉じるボタンを押してください</div>
          </>
        );
      }

      return <div>Global error page: {(error as Error).message}</div>;
    },
    notFoundComponent: Error404,
  },
);
