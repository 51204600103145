import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

import { foundations } from './foundations';
import { components } from './components';
import { styles } from './styles';

const breakpoints = {
  sm: '320px',
  md: '768px',
  xl: '1200px',
};

export const theme = extendTheme(
  {
    ...foundations,
    components,
    styles,
    breakpoints,
  },
  withDefaultColorScheme({ colorScheme: 'blue' }),
);
