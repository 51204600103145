import { ChakraProvider } from '@chakra-ui/react';
import {
  DefaultOptions,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { MAX_QUERY_RETRY_COUNT } from './utils/constants/common';
import { isBrowserAPIError } from './api/apiClient';
import { Authentication } from './providers/Authentication';
import { theme } from './theme';
import { Suspense } from 'react';
import { Loading } from './components/loading/Loading';
import { routeTree } from './routeTree.gen';

const config: DefaultOptions = {
  queries: {
    retry: (failureCount, error) => {
      if (
        isBrowserAPIError(error) &&
        (error.status >= 400 || error.status < 500)
      ) {
        return false;
      }

      if (isBrowserAPIError(error) && error.status >= 500) {
        return true;
      }

      if (failureCount === MAX_QUERY_RETRY_COUNT) {
        return false;
      }

      return true;
    },
  },
};
const queryClient = new QueryClient({ defaultOptions: config });

const router = createRouter({ routeTree, context: { queryClient } });
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Suspense fallback={<Loading />}>
        <Authentication>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </Authentication>
      </Suspense>
    </ChakraProvider>
  );
}

export default App;
