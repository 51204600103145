import type { ComponentMultiStyleConfig } from '@chakra-ui/theme';

export const Checkbox: ComponentMultiStyleConfig = {
  parts: ['control'],
  baseStyle: {
    control: {
      background: 'base.0',
      borderColor: 'base.4',
      _disabled: {
        background: 'base.1',
        borderColor: 'base.3',
      },
      _checked: {
        background: 'blue.5',
        borderColor: 'blue.5',
        _hover: {
          background: 'blue.6',
          borderColor: 'blue.6',
        },
        _disabled: {
          background: 'blue.1',
          borderColor: 'blue.1',
          color: 'blue.3',
        },
      },
      _invalid: {
        borderColor: 'red.5',
      },
    },
  },
};
