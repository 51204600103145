import { Box, Icon } from '@chakra-ui/react';
import { KeyboardTab } from '@mui/icons-material';

const BackgroundColors = [
  'carrier.88',
  'carrier.174',
  'carrier.185',
  'carrier.3',
  'carrier.146',
] as const;

export type GuidanceLocationIconProps = { guidanceIndex: number };

export const GuidanceLocationIcon: React.FC<GuidanceLocationIconProps> = ({
  guidanceIndex,
}) => {
  if (!BackgroundColors[guidanceIndex]) return null;

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: BackgroundColors[guidanceIndex],
        borderRadius: '20%',
        boxSizing: 'content-box',
        height: '24px',
        width: '24px',
      }}
    >
      <Icon color="white" viewBox="0 0 16 16">
        <KeyboardTab />
      </Icon>
    </Box>
  );
};
