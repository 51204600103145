import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { type ReactElement, Suspense, useEffect, useState } from 'react';
import { Loading } from '../../components/loading/Loading';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { isLoggedInValue } from '../../recoil/authorizedInfo';
import { isLoggedInAtom } from '../../recoil/authorizedInfo/atom';
import { driverAppSpotIdAtom } from '../../recoil/authorizedInfo/driverAppSpotIdAtom';
import { Navigate } from '@tanstack/react-router';
import { userValue } from '../../recoil/authorizedInfo';
import { useRecoilValue } from 'recoil';
import { signInWithCustomToken } from 'firebase/auth';

type DriverLoginEvent = {
  token: string;
  spotId: string;
  from: 'dev-loogia-for-driver' | 'stg-loogia-for-driver' | 'loogia-for-driver';
};

export const Authentication = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const auth = getAuth();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const setIsLoggedIn = useSetRecoilState(isLoggedInAtom);
  const setDriverAppSpotId = useSetRecoilState(driverAppSpotIdAtom);

  useEffect(() => {
    // ログイン状態を判定する処理
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setIsAuthorized(true);
      setIsLoggedIn(!!currentUser);
    });

    const handleDriverLogin = async (event: MessageEvent<DriverLoginEvent>) => {
      const data = event.data;
      if (!data.token || !data.spotId || !data.from) {
        return;
      }

      if (data.from !== import.meta.env.VITE_LOOGIA_OPERATION_INDENTIFIER) {
        return;
      }

      try {
        await signInWithCustomToken(auth, data.token);
        setDriverAppSpotId(data.spotId);
      } catch (error) {
        console.error('Failed to sign in with custom token', error);
        return;
      }
    };

    window.addEventListener('message', handleDriverLogin);

    return () => {
      unsubscribe();
      window.removeEventListener('message', handleDriverLogin);
    };
  });

  if (!isAuthorized) {
    return <Loading />;
  }

  return <Suspense fallback={<Loading />}>{children}</Suspense>;
};

export const LoggedIn = ({ children }: { children: ReactElement }) => {
  const isLoggedIn = useRecoilValueLoadable(isLoggedInValue);

  if (isLoggedIn.state === 'loading') {
    return <Loading />;
  }

  if (isLoggedIn.state === 'hasError') {
    throw isLoggedIn.contents;
  }

  if (!isLoggedIn.getValue()) {
    return <Navigate to="/" />;
  }

  return children;
};

export const DriverLoggedIn = ({ children }: { children: ReactElement }) => {
  const user = useRecoilValue(userValue);
  const isLoggedIn = useRecoilValueLoadable(isLoggedInValue);

  if (isLoggedIn.state === 'loading') {
    return <Loading />;
  }

  if (isLoggedIn.state === 'hasError') {
    throw isLoggedIn.contents;
  }

  if (!isLoggedIn.getValue() || user?.role !== 'driver') {
    return <Navigate to="/" />;
  }

  return children;
};
