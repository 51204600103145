import { Source, Layer } from 'react-map-gl';

export const ZenrinSource: React.FC = () => {
  return (
    <Source
      id="zenrin-tiles"
      type="raster"
      tiles={[import.meta.env.VITE_ZENRIN_MAP_TILE_URL]}
      tileSize={256}
      scheme="xyz"
      format="png"
    >
      <Layer id="zenrin-layer" type="raster" source="zenrin-tiles" />
    </Source>
  );
};
