import { createFileRoute, Outlet } from '@tanstack/react-router';
import { LoggedIn } from '../../providers/Authentication';
import { Header } from '../../components/header';

const AppManager = () => {
  return (
    <LoggedIn>
      <>
        <Header />
        <Outlet />
      </>
    </LoggedIn>
  );
};

export const Route = createFileRoute('/app/_managerloggedin')({
  component: AppManager,
});
